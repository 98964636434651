import React, { FC } from 'react';

type RedirectProps = {
	link: string;
};

export const Redirect: FC<RedirectProps> = ({ link }) => {
	window.location.replace(link);

	return <div />;
};
